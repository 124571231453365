@import 'shared/styles/variables';


.list {
	display: flex;
	align-items: center;

	.item {
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}

	.link {
		font-size: 14px;
		text-decoration: none;
		color: $white;
		display: flex;
		align-items: center;

		svg {
			width: 25px;
			fill: white;
			stroke: white;
			margin-right: 10px;
		}
	}
}