.Map {
	position: relative;
	overflow: hidden;
	padding-bottom: 25%;

	@media screen and (max-width: 767px) {
		padding-bottom: 75%;
	}

	.mapElement {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}