@import 'shared/styles/variables';


.list {
	display: flex;
	align-items: center;

	.item {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	.link {
		font-size: 14px;
		text-decoration: none;
		color: $white;
		display: flex;
		align-items: center;
		// margin-right: 5px;

		svg {
			width: 20px;
			height: 20px;
			fill: white;
		}
	}
}