@use 'shared/styles/variables' as *;


.body {
	min-height: 100vh;
}

.flexWrapper {
	display: flex;
	flex-direction: column;
	min-height: 125px;

}

.wrapper {
	display: flex;
	justify-content: space-between;

	.lang {
		margin-left: 40px;
	}
}

.logo {
	display: block;
	max-width: 250px;
	width: 100%;
	fill: $primary-color;
	transition: all $transition;

	&:hover {
		fill: $secondary-color;
	}
}

.main {
	flex: 1 0 auto;
	min-height: 100vh;
}

.header {
	background-color: transparent;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&>a {
		line-height: 0;
	}
}

.item {
	display: inline-flex;
	justify-content: center;
	text-decoration: none;
	padding: 5px 15px;

	font-size: 18px;
	font-weight: 400;
	color: $blue;
	border-radius: 8px;
	border: 1px solid transparent;

	margin-right: 30px;

	transition: all ease-in 150ms;

	&:hover {
		color: $primary-color;
	}

	&:last-child {
		margin-right: 0;
	}
}

.item.active {
	color: $primary-color;
	font-weight: 400;
	text-decoration: none;
}

// media

.nav_mobile {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;

	opacity: 0;

	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);

	transition: all 0.4s ease;

	&.showMob {
		z-index: 5;
		opacity: 1;
	}
}

.nav_mobile_wrapper {
	position: absolute;
	z-index: 999;
	top: 0;
	right: 0;
	transform: translateX(100%);
	width: 400px;
	height: 100vh;

	padding-left: 40px;
	background-color: $primary-bg-color;
	box-shadow: -15px 0px 30px rgba(0, 0, 0, 0.15);

	transition: all 0.4s ease;

	&.show {
		transform: translateX(0);
	}

	.mobileLogo {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.btn_open {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	color: black;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.btn_close {
	position: absolute;
	top: 20px;
	right: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.link_items {
	margin-bottom: 10px;

	transition: all 0.4s ease;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		padding-left: 10px;
	}
}

.item_mob {
	display: block;
	text-decoration: none;
	padding: 5px 15px;
	margin-bottom: 20px;

	font-size: 18px;
	color: white;

	transition: all ease-in 250ms;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		color: $primary-color;
		padding-right: 10px;
	}

	&.active_mob {
		color: $primary-color;
		text-decoration: none;
	}
}

.back {
	background-color: $primary-bg-color;
	display: none;

	&.show {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.nav_mobile_wrapper {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		transform: translateX(100%);
		width: 300px;
		height: 100%;

		padding-left: 30px;
		background-color: $primary-bg-color;
		box-shadow: -15px 0px 30px rgba(0, 0, 0, 0.15);

		transition: all 0.4s ease;

		&.show {
			transform: translateX(0);
		}
	}
}

@media screen and (max-width: 767px) {
	.btn_open {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0;
		color: black;
		background-color: transparent;
		border: none;
		cursor: pointer;
	}
}