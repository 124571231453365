@use './variables' as *;

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: $main-font;
	font-style: normal;
	margin: 0;
	color: $black;
	background-color: $lblue;
	transition: 0.3s ease-in-out;
	height: 100vh;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh !important;
	background-color: rgb(241 250 250);

	background-image: url(http://localhost:3000/static/media/layer-blur.f24ff97760325563b433.png);
	background-position: top left;
	background-size: contain;
	background-repeat: no-repeat;

	main {
		flex: 1;
	}
}



html {
	scroll-behavior: smooth;
	height: 100%;
}

svg {
	display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#hero {
	min-height: 700px;
}

.active {
	color: $secondary-color;
}



#services {

	.splide {
		.splide__slide {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 15px;
				background-color: transparent;
				transition: all 0.3s ease-in-out;
			}

			&.is-active {

				&::after {
					background-color: $primary-color;
				}
			}
		}

		.splide__arrow {
			background: $primary-color !important;

			svg {
				fill: $white;
			}
		}
	}
}