@use '../../styles/variables' as *;

.btn {
	position: fixed;
	z-index: 7;
	bottom: -50px;
	right: 50px;
	opacity: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: none;
	outline: none;
	border: 2px solid $white;
	background-color: $secondary-color;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
	color: $primary-color;
	transition: all $transition;

	&.active {
		bottom: 50px;
		opacity: 1;
	}

	&:hover,
	&:active {
		color: $secondary-color;
		background-color: $primary-color;
	}


	.icon {
		font-weight: 300;
		font-size: 42px;
		color: $white;
	}
}