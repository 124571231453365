@import 'shared/styles/variables';

.inner_wrapper {
	padding: 40px 0;

}

.title {
	text-align: center;
	text-transform: uppercase;
	font-size: 48px;
	margin-bottom: 60px;

	@media screen and (max-width: 767px) {
		font-size: 32px;
	}
}

.items {
	display: flex;
	justify-content: space-around;
	gap: 20px;
	padding: 0 30px;
	padding-bottom: 20px;

	@media screen and (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	.item {
		width: calc(100% / 3);

		@media screen and (max-width: 767px) {
			width: 100%;
			text-align: center;
			margin: 0;
			margin-bottom: 20px;
		}
	}



	.contactList {
		@media screen and (max-width: 767px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.contactItem {

			font-size: 14px;
			color: black;
			margin-bottom: 10px;

			a {
				display: flex;
				align-items: center;
				color: inherit;
				text-decoration: none;
				transition: all $transition;

				@media screen and (max-width: 767px) {
					text-align: center;
				}

				.icon {
					display: inline-block;
					margin-right: 10px;
					width: 20px;
					height: 20px;
					transition: all $transition;

					&.phone {
						stroke: $black;
					}
				}

				span {
					line-height: 1.5;
				}
			}

			&:hover {
				a {
					color: $primary-color;
				}

				.icon {
					fill: $primary-color;
					stroke: $primary-color !important;
				}
			}
		}

	}
}

.subtitle {
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
	margin-bottom: 1rem;
	font-size: 1.1rem;
	font-weight: 500;
}

.btn {
	padding: 13px 28px;
	border: none;
	border-radius: 60px;

	color: var(--main-bg);
	background-color: var(--text-color);
}

.modal_hidden {
	transform: scale(0.5);
	opacity: 0;
	transition: all 0.4s linear;
}

.modal_show {
	transform: scale(1);
}

.socialList {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	padding-top: 100px;

	.socialIcon {
		cursor: pointer;
		width: 50px;
		margin-left: 30px;

		transition: fill 150ms linear;

		fill: $primary-color;

		&:first-child {
			margin: 0;
		}

		&:hover {
			fill: rgb(255, 174, 0);
		}

		@media screen and (max-width: 767px) {
			width: 40px;
		}
	}
}

.payment_container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	padding: 0px 30px;

	@media screen and (max-width: 767px) {
		padding-top: 10px;
		justify-content: center;
	}

	.subtitle {
		margin-bottom: 0;
		// margin-right: 20px;
		white-space: nowrap;


	}

	.payment {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		.pay {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}