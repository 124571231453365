@import '../../styles/variables';

.container {
	padding: 20px 0;
	background-color: $black;

}

.content {
	display: flex;
	justify-content: center;

	.text {
		text-align: center;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 1px;
		color: $white;
		margin: 0 auto;
	}
}

.payment_container {
	display: flex;
	align-items: center;
	padding: 40px 0;

	.subtitle {
		font-weight: 400;
		line-height: 1.5;
		letter-spacing: 0.00938em;
		font-size: 1.1rem;
	}

	.payment {
		display: flex;
		align-items: center;
		justify-content: center;

		.pay {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}