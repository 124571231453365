@font-face {
	font-family: 'Ametrine';
	src: url('../../assets/fonts/ametrine.woff2') format('woff2'),
		url('../../assets/fonts/ametrine.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Ametrine';
	src: url('../../assets/fonts/ametrine_italic.woff2') format('woff2'),
		url('../../assets/fonts/ametrine.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}