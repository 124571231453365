@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&family=Roboto:wght@100;300;400;700&display=swap');


$main-font: 'Poppins', sans-serif;
$primary-font: 'Roboto', sans-serif;

$lblue : rgb(247, 253, 251);
$blue : #36b393;
$grey : rgba(117, 117, 117, 0.46);


$primary-color: #e8408a;
$secondary-color: #36b393;

$accent-online: #938BA1;
$accent-offline: #B2E4DB;

$white: #fff;
$black: #000;
$black-light: #7D7D8E;
$primary-bg-color: #021b26;
$secondary-bg-color: #e8408a;

$active-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);

$transition: 0.3s ease-in-out;
$radius-s: 10px